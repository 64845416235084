import Logo from '../../assets/navbaricon.png'
import { useEffect, useState } from 'react'
import profile_logo from '../../assets/profile-logo.png'
import { useAuth } from '../../context/logincontext'
import { Link, useNavigate, useLocation } from 'react-router-dom'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const { logout } = useAuth()
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
  const navigate = useNavigate()
  const location = useLocation()
  // const user = JSON.parse(sessionStorage.getItem("user"));
  const [homevisible,Sethomevisible]= useState(true)


  useEffect(() => {
    const switchAccount = sessionStorage.getItem("owner_account");
    if (switchAccount !== null) {
      Sethomevisible(switchAccount === "true"); // Converts the string value to boolean
    }
  }, [homevisible,sessionStorage.getItem("owner_account")]);

  useEffect(() => {
    // Initialize user from sessionStorage when the component first mounts
    const savedUser = JSON.parse(sessionStorage.getItem('user'))
    if (savedUser) {
      setUser(savedUser)
    }

    const handlereloadusecredentials = () => {
      // Update user from sessionStorage when the event is triggered
      const updatedUser = JSON.parse(sessionStorage.getItem('user'))
      setUser(updatedUser)
    }

    // Add event listener to update user when session storage changes
    window.addEventListener('reloadusecredentials', handlereloadusecredentials)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener(
        'reloadusecredentials',
        handlereloadusecredentials
      )
    }
  }, []) // Empty dependency array ensures this runs only once

  // Optional: Add a way to manually trigger user change (e.g., button or another action)
  const updateSessionUser = newUser => {
    sessionStorage.setItem('user', JSON.stringify(newUser))
    window.dispatchEvent(new Event('reloadusecredentials'))
  }
  const handleLogout = () => {
    logout()
    navigate('/')
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  const isActive = path => location.pathname === path // Function to check if the current path is active

  useEffect(() => {
    if (!sessionStorage.getItem('user') || !user) {
      navigate('/')
    }
    if (location.pathname === '/home') {
      sessionStorage.removeItem('store_id')
      window.dispatchEvent(new CustomEvent('checkStoreId', { detail: false }))
    }
  })

  return (
    <div className='bg-[#232328] w-full h-[59px] flex items-center text-[#FFFFFF] justify-between p-3 rounded-[9px]'>
      {/* Logo Section */}
      <div className='flex justify-start items-center'>
        <img src={Logo} alt='logo' className='w-auto h-[27px]' />
        {/* <p className="text-[12px] mt-[-2px] font-bold ml-2">MagBot</p> */}
      </div>

      {/* Hamburger Menu for Mobile */}
      <div className='block lg:hidden'>
        <button
          className='text-white focus:outline-none'
          onClick={toggleMobileMenu}
        >
          <svg
            className='w-6 h-6'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h16M4 18h16'
            ></path>
          </svg>
        </button>
      </div>

      {/* Navigation Menu for Desktop */}
      <div className={`hidden lg:flex justify-between w-full lg:w-auto `}>
        <ul className='flex space-x-9 cursor-pointer'>
          { homevisible &&
            <li onClick={() => navigate('/home')}>
              <p
                className={`capitalize font-bold text-[11.28px] leading-[12px] text-white ${
                  location.pathname === '/home' ? 'hidden' : ''
                } ${
                  isActive('/home') ? 'text-opacity-100' : 'text-opacity-60'
                }`}
              >
                Home
              </p>
            </li>
            }
          <li onClick={() => navigate('/training')}>
            <p
              className={`capitalize font-bold text-[11.28px] leading-[12px] text-white ${
                location.pathname === '/home' ? 'hidden' : ''
              } ${
                isActive('/training') ? 'text-opacity-100' : 'text-opacity-60'
              }`}
            >
              Training
            </p>
          </li>
          <li onClick={() => navigate('/chats')}>
            <p
              className={`capitalize font-bold text-[11.28px] leading-[12px] text-white ${
                location.pathname === '/home' ? 'hidden' : ''
              } ${isActive('/chats') ? 'text-opacity-100' : 'text-opacity-60'}`}
            >
              Chats
            </p>
          </li>
          <li onClick={() => navigate('/bot-customization')}>
            <p
              className={`capitalize font-bold text-[11.28px] leading-[12px] text-white ${
                location.pathname === '/home' ? 'hidden' : ''
              } ${
                isActive('/bot-customization')
                  ? 'text-opacity-100'
                  : 'text-opacity-60'
              }`}
            >
              Bot Customization
            </p>
          </li>
          {/* <li onClick={() => navigate('/analytics')}>
            <p
              className={`capitalize font-bold text-[11.28px] leading-[12px] text-white ${
                location.pathname === '/home' ? 'hidden' : ''
              } ${
                isActive('/analytics') ? 'text-opacity-100' : 'text-opacity-60'
              }`}
            >
              Analytics
            </p>
          </li> */}
          <li onClick={() => navigate('/feedback')}>
            <p
              className={`capitalize font-bold text-[11.28px] leading-[12px] text-white ${
                location.pathname === '/home' ? 'hidden' : ''
              } ${
                isActive('/feedback') ? 'text-opacity-100' : 'text-opacity-60'
              }`}
            >
              Feedback
            </p>
          </li>
          {/* <li onClick={() => navigate('/subscription')}>
            <p
              className={`capitalize font-bold text-[11.28px] leading-[12px] text-white ${
                location.pathname === '/home' ? 'hidden' : ''
              } ${
                isActive('/subscription')
                  ? 'text-opacity-100'
                  : 'text-opacity-60'
              }`}
            >
              Subscription
            </p>
          </li> */}
        </ul>
      </div>

      {/* User Profile and Dropdown for Desktop */}
      <div
        className='hidden lg:flex bg-white w-[200px] h-[45px] rounded-[8px] relative items-center justify-between px-3 cursor-pointer'
        onClick={toggleDropdown}
      >
        <div className='flex justify-start items-center space-x-2'>
          <img
            src={profile_logo}
            alt='Profile'
            className='w-[30px] h-[30px] rounded-full'
          />
          <div className='block'>
            <p
              className='text-[#272D37] text-[10px] font-semibold leading-[22px] truncate max-w-[110px]'
              title={user?.name}
            >
              {user?.name}
            </p>
            <p className='font-medium text-[#5F6D7E] text-[8px]'>
              {user?.email}
            </p>
          </div>
        </div>

        <svg
          width='18'
          height='19'
          viewBox='0 0 18 19'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M3.4451 7.18079L8.35924 12.9155C8.43845 13.0079 8.53671 13.082 8.64727 13.1329C8.75784 13.1837 8.87809 13.21 8.99979 13.21C9.12148 13.21 9.24174 13.1837 9.3523 13.1329C9.46287 13.082 9.56112 13.0079 9.64034 12.9155L14.5545 7.18079C15.0235 6.63341 14.6346 5.7879 13.9139 5.7879L4.08424 5.7879C3.36354 5.7879 2.97471 6.63341 3.4451 7.18079Z'
            fill='#565656'
          />
        </svg>

        {isOpen && (
          <div className='absolute top-[55px] z-10 text-black right-0 bg-white shadow-md rounded-[8px] w-[160px]'>
            <ul className='py-2 text-[12px]'>
              <li
                onClick={handleLogout}
                className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
              >
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className='lg:hidden absolute ml-5 mr-5 rounded-md top-[69px] left-0 right-0 bg-[#232328] z-50 p-4'>
          <ul className='flex flex-col space-y-4'>
            <li
              onClick={() => {
                navigate('/home')
                setIsMobileMenuOpen(false)
              }}
            >
              <p className='capitalize font-bold text-white'>Home</p>
            </li>
            <li
              onClick={() => {
                navigate('/training')
                setIsMobileMenuOpen(false)
              }}
            >
              <p className='capitalize font-bold text-white'>Training</p>
            </li>

            <li
              onClick={() => {
                navigate('/chats')
                setIsMobileMenuOpen(false)
              }}
            >
              <p className='capitalize font-bold text-white'>Chats</p>
            </li>
            <li
              onClick={() => {
                navigate('/bot-customization')
                setIsMobileMenuOpen(false)
              }}
            >
              <p className='capitalize font-bold text-white'>
                Bot Customization
              </p>
            </li>
            {/* <li
              onClick={() => {
                navigate('/analytics')
                setIsMobileMenuOpen(false)
              }}
            >
              <p className='capitalize font-bold text-white'>Analytics</p>
            </li> */}
            <li
              onClick={() => {
                navigate('/feedback')
                setIsMobileMenuOpen(false)
              }}
            >
              <p className='capitalize font-bold text-white'>Feedback</p>
            </li>
            {/* <li
              onClick={() => {
                navigate('/subscription')
                setIsMobileMenuOpen(false)
              }}
            >
              <p className='capitalize font-bold text-white'>Subscription</p>
            </li> */}

            {/* Mobile Profile Section */}
            <li className='mt-4'>
              <div className='flex justify-start pt-2 pb-2 pl-2 pr-2 rounded-[5px]  items-center space-x-2 bg-white'>
                <img
                  src={profile_logo}
                  alt='Profile'
                  className='w-[30px] h-[30px] rounded-full '
                />
                <div>
                  <p className='text-black text-sm font-semibold'>
                    {user.name}
                  </p>
                  <p className='text-black text-xs'>{user.email}</p>
                </div>
              </div>
            </li>

            <li
              onClick={handleLogout}
              className='text-white mt-4 cursor-pointer'
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default Navbar
