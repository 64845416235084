import React, { useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css' // Import the styles for the toast notifications
import { useEffect } from 'react'

// Allowed file MIME types
const fileTypes = ['image/jpeg', 'image/png', 'image/gif']
const maxFileSize = 2 * 1024 * 1024 // 2MB in bytes
const maxFiles = 5 // Maximum number of files allowed

function ImageDragDrop ({ onFilesChange }) {
  const [files, setFiles] = useState([]) // State to store an array of files

  useEffect(() => {
    const handlefeedbackSubmitted = event => {
      const feedback  = event.detail
      if (feedback) {
        setFiles([])
      }
    }

    window.addEventListener('feedbackSubmitted', handlefeedbackSubmitted) // Add event listener for paste event
    return () => {
      window.removeEventListener('feedbackSubmitted', handlefeedbackSubmitted) // Remove event listener on component unmount
    }
  }, [files])

  const handleDrop = event => {
    event.preventDefault() // Prevent default browser behavior

    const droppedFiles = Array.from(event.dataTransfer.files) // Get the dropped files
    const validFiles = []

    droppedFiles.forEach(file => {
      if (fileTypes.includes(file.type)) {
        if (file.size <= maxFileSize) {
          validFiles.push(file) // If the file is valid and under 2MB, add it to the validFiles array
        } else {
          toast.error('File size exceeds 2MB') // Show toast error for file size
        }
      } else {
        toast.error('Invalid file type. Only images are allowed.') // Show toast error for invalid file type
      }
    })

    // Check if the total files after adding new ones exceed the maxFiles limit
    if (files.length + validFiles.length > maxFiles) {
      toast.error(`You can only upload a maximum of ${maxFiles} files.`) // Show toast error
      return
    }

    // Add valid files to state
    validFiles.forEach(file => {
      handleBotImageChange(file)
    })
  }

  const handleFileSelect = event => {
    const selectedFiles = Array.from(event.target.files) // Get the selected files
    const validFiles = []

    selectedFiles.forEach(file => {
      if (fileTypes.includes(file.type)) {
        if (file.size <= maxFileSize) {
          validFiles.push(file) // If the file is valid and under 2MB, add it to the validFiles array
        } else {
          toast.error('File size exceeds 2MB') // Show toast error for file size
        }
      } else {
        toast.error('Invalid file type. Only images are allowed.') // Show toast error for invalid file type
      }
    })

    // Check if the total files after adding new ones exceed the maxFiles limit
    if (files.length + validFiles.length > maxFiles) {
      toast.error(`You can only upload a maximum of ${maxFiles} files.`) // Show toast error
      return
    }

    // Add valid files to state
    validFiles.forEach(file => {
      handleBotImageChange(file)
    })
  }

  const handleBotImageChange = async file => {
    const fileAlreadyUploaded = files.some(existingFile => existingFile.name === file.name)
  
    if (fileAlreadyUploaded) {
      toast.error('This image has already been uploaded.') // Show toast error for already uploaded image
      return // Prevent further processing
    }
  
    // Preview the image
    const reader = new FileReader()
    reader.onloadend = async () => {
      // Prepare the form data to send as binary
      const formData = new FormData()
      formData.append('image', file) // Append the file with the correct field name ("image")

      try {
        // Send the file to the server using fetch with the FormData (binary data will be automatically handled)
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/upload/image`,
          {
            method: 'POST',
            body: formData, // Body is the form data which includes the binary file
            headers: {
              ...(sessionStorage.getItem('token')
                ? {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                  }
                : {}) // Include the token in headers if it exists
            }
          }
        )

        // Check if the upload was successful
        if (response.ok) {
          const data = await response.json()
          const uploadedImageUrl = `${process.env.REACT_APP_AWS_URL}${data.data.filename}` // Assuming the response contains the URL

          // Add the uploaded file and its URL to the files state
          const updatedFiles = [
            ...files,
            { name: file.name, url: uploadedImageUrl }
          ]

          setFiles(updatedFiles)

          // If a parent callback is provided, pass the updated files state
          if (onFilesChange) {
            onFilesChange(updatedFiles) // Pass the files to the parent component
          }
        } else {
          toast.error('Failed to upload image') // Show toast error
        }
      } catch (error) {
        toast.error('Error uploading image: ' + error.message) // Show toast error
      }
    }
    reader.readAsDataURL(file) // Read the file for preview
  }

  const handleDragOver = event => {
    event.preventDefault() // Prevent default behavior to allow drop
  }

  // Handle file removal
  const handleRemoveFile = fileName => {
    const updatedFiles = files.filter(file => file.name !== fileName)
    setFiles(updatedFiles)

    // If a parent callback is provided, pass the updated files state
    if (onFilesChange) {
      onFilesChange(updatedFiles) // Pass the files to the parent component
    }
  }

  return (
    <div>
      <div
        className='border-gray-300 border-dashed border-[1px] rounded-md p-4 text-start'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type='file'
          multiple
          accept={fileTypes.join(',')}
          onChange={handleFileSelect}
          className='mt-2 text-sm font-medium w-full max-w-xs sm:text-base sm:max-w-sm md:text-sm md:max-w-md lg:max-w-lg'
        />
      </div>

      <div className='mt-4'>
        {/* <h3>Uploaded Images:</h3> */}
        <ul className='flex gap-5 flex-wrap'>
          {files.map((file, index) => (
            <li key={index} className='relative w-[70px] h-[70px]'>
              {/* Display the image thumbnail */}
              <img
                src={file.url}
                alt={file.name}
                className='w-full h-full object-contain rounded-md '
              />
              <button
                type='button'
                className='absolute top-[3px] right-[-9px] bg-transparent p-1 rounded-full text-red-500'
                onClick={() => handleRemoveFile(file.name)}
              >
                <svg
                  width='12'
                  height='12'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M17.0151 2.90622C13.1401 -0.968741 6.78119 -0.968741 2.90622 2.90622C-0.968741 6.78119 -0.968741 13.1401 2.90622 17.0151C6.78119 20.89 13.0407 20.89 16.9157 17.0151C20.7907 13.1401 20.89 6.78119 17.0151 2.90622ZM12.7427 14.1337L9.96065 11.3517L7.17862 14.1337L5.78761 12.7427L8.56963 9.96065L5.78761 7.17862L7.17862 5.78761L9.96065 8.56964L12.7427 5.78761L14.1337 7.17862L11.3517 9.96065L14.1337 12.7427L12.7427 14.1337Z'
                    fill='#FF3407'
                  />
                </svg>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ImageDragDrop
